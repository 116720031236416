<template>
  <div>
    <div class="vimeo-wrapper">
      <iframe src="https://player.vimeo.com/video/510692332?background=1&autoplay=1&loop=1&byline=0&title=0"
              frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
      </iframe>
    </div>

    <div class="h-screen flex justify-center items-center">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col login-box">
        <div class="mb-4">
          <label class="block text-white text-sm font-bold mb-2" for="code">
            Syötä koodi <i class="fa fal fa-info"></i>
          </label>
          <input
              v-model.trim="loginForm.code"
              :class="{ 'border-red-700': loginForm.error }"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3"
              id="code"
              placeholder=""
          />
          <p v-if="loginForm.error" class="m-0 text-red-700">
            {{ loginForm.errorMessage }}
          </p>
        </div>
        <div class="flex items-center justify-between">
          <button
              @click="login()"
              class="bg-blue-600 py-2 px-4 rounded btn-yellow"
              type="button"
          >
            <svg
                v-if="isLoading"
                class="animate-spin inline -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
              <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
              ></circle>
              <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            {{ isLoading ? "Tarkistetaan" : "Valmis" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authenticateWithToken, checkCode} from "@/firebase";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        error: false,
        errorMessage: "",
        code: "",
      },
      isLoading: false,
    };
  },
  methods: {
    login() {
      this.isLoading = true;
      checkCode({code: this.loginForm.code}).then(async (result) => {
        this.isLoading = false;
        const data = result.data;
        if (data.error) {
          this.loginForm.error = true;
          this.loginForm.errorMessage = data.message;
          console.error(`Error: ${data.message}`);
        } else {
          this.loginForm.error = false;
          console.log(`${data.message}`);
          await authenticateWithToken(data.token);
          await this.$router.push("/live");
        }
      });
    },
  },
};
</script>

<style>
.vimeo-wrapper {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}

.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
